import React from "react";
import {
  Wrapper,
  Title,
  Description,
  Container,
  FrameAnchor,
  Frame,
  FeatureTitle,
  // FeatureDescription,
  FeatureImage,
  ProductTitle,
  // ProductDescription,
  ProductImage,
  FeatureLogo,
  FeatureVersions,
} from './feature-tree.styled';
import { Button, ZWrapper } from "../../ui-components";
import LogoSimpleImageSrc from '../../assets/img/logo-simple.png';
import { markdown2HTML } from '../../utils';



const getFeatureSide = (index) => {
  switch (index) {
    default:
    case 0:
      return "left";
    case 1:
      return "middle";
    case 2:
      return "right";
  }
}

const getPrductSide = (index) => {
  switch (index) {
    default:
    case 0:
      return "left";
    case 1:
      return "right";
  }
}

const FeatureTree = ({ title, description, features, products }) => (
  <>
    <Wrapper>
      <Container center>
        <Title dangerouslySetInnerHTML={{ __html: markdown2HTML(title) }} />
        <Description>{description}</Description>
        <Frame container_pictogram>
          {features.map((data, index) => (
            <Frame
              key={index}
              position={getFeatureSide(index)}
              row
            >
              <ZWrapper
                size="small"
                color="primary"
              >
                <Frame frame>
                  <Frame image>
                    <FeatureImage
                      image={data.image.childImageSharp.thumb}
                      alt={data.title}
                    />
                  </Frame>
                </Frame>
                <FeatureTitle>{data.title}</FeatureTitle>
              </ZWrapper>
            </Frame>
          ))}
        </Frame>
      </Container>
    </Wrapper>
    <Wrapper color="background">
      <Container center>
        <Frame container>
          <Frame center>
            <FeatureLogo src={LogoSimpleImageSrc} alt="BeTRITON Versions" />
            <FeatureVersions><strong>BeTRITON</strong> VERSIONS</FeatureVersions>
          </Frame>
        </Frame>

        <Frame container>
          {products.map((data, index) => (
            <Frame
              key={index}
              position="center"
              productrow
            >
              <Frame productcol>
                <FrameAnchor
                  href={data.url}
                >
                  <ZWrapper
                    size="small"
                    color="primary"
                  >
                    <ProductImage
                      image={data.image.childImageSharp.thumb}
                      alt={data.title}
                    />
                  </ZWrapper>

                </FrameAnchor>
                <ProductTitle dangerouslySetInnerHTML={{ __html: markdown2HTML(data.title) }} />
                {data.url && (
                  <Button
                    type={data.url_type}
                    to={data.url}
                    target="_self"
                    color={data.url_color}
                    size="normal"
                    onClick={(e) => { }}
                  >
                    {data.url_name}
                  </Button>
                )}
              </Frame>
            </Frame>
          ))}
        </Frame>
      </Container>
    </Wrapper>
  </>
)

export default FeatureTree;
