import React from "react";
import {
  Wrapper,
  Title,
  Container,
  DescriptionMain,
} from './mission-call-on.styled';
import { markdown2HTML } from '../../utils';


const MissionCallOn = ({ title, description }) => {
  return (
    <Wrapper>
      <Container center>
        <Title dangerouslySetInnerHTML={{ __html: markdown2HTML(title) }} />
        <DescriptionMain dangerouslySetInnerHTML={{ __html: markdown2HTML(description) }} />
      </Container>
    </Wrapper>
  )
}

export default MissionCallOn;
