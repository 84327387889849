import React from "react";
import styled from "styled-components";
import Faq from "../faq-acordeon/faq-acordeon";



const Wrapper = styled.div`
  text-align: center;
  padding: 40px 0px 64px;

  @media ${({theme}) => theme.device.mobile}{
    padding-top: 24px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const Container = styled.div`
  position: relative;
  max-width: ${({ theme }) => theme.size.container}px;
  margin: 0 auto;
  text-align: center;
  display: block;

  padding-left: 8px
  padding-right: 8px;
`


const ContentFAQ = styled.div`
  margin-top: 10px;
  margin-right: 16px;
  text-align: right;

  @media ${({theme}) => theme.device.phone}{
    text-align: center;
    margin: 24px 0;
  }
`;

const Title = styled.h3`
  font-family: "Quantico", sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 32px;
  line-height: 35px;
  padding-bottom: 8px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.text.primary};
  position: relative;
  display: inline-block;

  @media ${({ theme }) => theme.device.phone}{
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0;
  }

  &::after{
    position: absolute;
    content: " ";
    display: block;
    background: linear-gradient(90deg, ${({ theme }) => theme.background.default} 0%, ${({ theme }) => theme.primary.main} 50%, ${({ theme }) => theme.background.default} 100%);
    height: 1px;
    left: 10%;
    right: 10%;
    bottom: -5px;
  }
`;


const FAQSelection = ({ list }) => (
  <Wrapper>
    <Container>
      <Title>FAQ</Title>
      {list.map((data, index) => <Faq key={index} answer={data.answer} question={data.question} isOpen={index === 0} />)}
      <ContentFAQ>
        <a href="/faq/">Read more questions &amp; answers</a>
      </ContentFAQ>
    </Container>
  </Wrapper>
)

export default FAQSelection;

